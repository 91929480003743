@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@font-face {
  font-family: 'kanit';
  src: 'assets/fonts/Kanit-Light.ttf';
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'kanit';
  src: 'assets/fonts/Kanit-LightItalic.ttf';
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: 'kanit';
  src: 'assets/fonts/Kanit-Medium.ttf';
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'kanit';
  src: 'assets/fonts/Kanit-MediumItalic.ttf';
  font-style: italic;
  font-weight: bold;
}

@border-radius-sm: 2px;
@border-radius-base: 5px;
@border-radius-lg: 10px;

@height-base: 32px;
@height-lg: 40px;
@height-sm: 24px;

@primary-color: #428bcb;
@warning-color: #ff8621;
@second-color: #276ba7;

@text-color: #9b9b9b;
@text-color-hover: @primary-color;

@link-color: @primary-color;
@link-hover-color: #abd7ff;

@btn-line-height: 1;
@btn-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
@btn-padding-horizontal-lg: 20px;

@btn-primary-color: white;
@btn-primary-bg: @primary-color;
@btn-primary-shadow: @btn-shadow;

@btn-default-color: @text-color;
@btn-default-border: @text-color;

@btn-disable-color: white;
@btn-disable-bg: #d1d1d1;
@btn-disable-border: #d1d1d1;

@btn-text-hover-bg: transparent;

@collapse-header-bg: #d1d1d1;
@collapse-content-bg: #ffffff;
@collapse-panel-border-radius: 12px;

@input-color: @second-color;
@dropdown-selected-color: @second-color;
@select-item-selected-color: @second-color;

@radio-dot-color: @primary-color;


html,
body,
* {
  padding: 0;
  margin: 0;
  /* font-family: Kanit, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: 'kanit', Roboto, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

span,
p {
  font-size: 16px;
}

.ant-form-item-label > label {
  color: @second-color;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: '*';
}

.ant-table-column-title {
  font-weight: 600;
}

.ant-table tr:nth-child(2n) td {
  //   background-color: #fbfbfb !important;
  background-color: #f8f8f8 !important;
}
.ant-table thead {
  background-color: #f1f1f1 !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-prev button,
.ant-pagination-next button,
.ant-select-arrow:not(.ant-select-disabled) {
  color: @primary-color;
  top: 45%;
  &:hover,
  &:focus {
    color: @primary-color;
    opacity: 0.8;
  }
}

// .ant-select:not(.ant-select-customize-input, .ant-select-disabled)
//   .ant-select-selector,
// .ant-pagination-simple .ant-pagination-simple-pager input {
//   border: 1px solid @primary-color;
// }
.ant-pagination-simple .ant-pagination-simple-pager {
  height: auto;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  height: 32px;
}

.test {
  border: 1px solid #000;
}
.animation {
  animation: vibrate 0.2s linear;
  -webkit-animation: vibrate 0.2s linear;
  border: 1px solid #ff4d4f;
  color: #ff4d4f;

  &.ant-input {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid #ff4d4f;
      -webkit-box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
      box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
    }
  }
}

.ant-select.ant-select-single:not(.ant-select-disabled) {
  color: @second-color;
}
.ant-select-item-option-content {
  color: @primary-color;
}
.ant-select-disabled > .ant-select-arrow {
  color: @disabled-color !important;
}

.ant-table-column-sorter,
.ant-table-column-sorters:hover .ant-table-column-sorter,
.ant-table-filter-trigger {
  color: #ffffff;
}

.testInput > .input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes vibrate {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

.ant-btn-lg {
  border-radius: @border-radius-lg;
}

// .ant-input-affix-wrapper {
//   border-radius: @border-radius-lg;
// }

.ant-input-lg {
  height: @height-lg;
}
.ant-input-password,
.ant-input-affix-wrapper {
  & > .ant-input-lg {
    height: @height-sm;
  }
}
.ant-table-cell, .ant-table-column-title {
  color: @second-color;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 20px 0px 0px 0px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0px 20px 0px 0px;
}

.ant-table-thead > tr > th {
  background: #d9edff !important;
  color: @second-color;
  font-size: 16px;
  font-weight: 600;
}

.ant-btn-primary {
  &.warning {
    color: white;
    background-color: @warning-color;
    border-color: @warning-color;

    &:hover,
    &:focus {
      border-color: @warning-color-hover;
      background-color: @warning-color-hover;
    }
  }

  &.link {
    color: @link-color;
    background-color: white;
    border-color: @link-color;

    &:hover,
    &:focus {
      color: @link-hover-color;
      border-color: @link-hover-color;
    }
  }
}

.ant-btn-link {
  color: @primary-color;

  &:hover,
  &:focus {
    color: @link-hover-color;
  }
}

.ant-card.forget-password-card {
  max-width: 500px;
  display: inline-flex;
  min-height: 400px;
  height: 100%;
  width: 100%;
  border-radius: 20px;

  .ant-card-body {
    width: 100%;
    /* margin: auto; */
    padding: 0px;
  }
}

.ant-collapse {
  border-radius: 20px;
}

.ant-collapse > .ant-collapse-item:first-child,
.ant-collapse > .ant-collapse-item:first-child > .ant-collapse-header {
  border-radius: 20px 20px 0px 0px;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0px 0px 20px 20px;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0px;
}

.ant-collapse-header[aria-expanded='true'] {
  background: #d9edff !important;
  .header {
    color: @second-color;
  }
}
.ant-collapse-header[aria-expanded='false'] {
  background: #d1d1d1 !important;
  .header {
    color: white;
  }
}

.ant-collapse-header > .header {
  color: @second-color;
  font-weight: bold;
  font-size: 14px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  display: none;
}

.ant-tree-switcher {
  display: none;
}

.ant-form-vertical .ant-form-item-label > label {
  font-weight: bold;
}

.ant-tree-treenode {
  color: @primary-color
}

.ant-radio-wrapper.ant-radio-wrapper-checked {
  color: @primary-color;
}

.ant-dropdown-menu-title-content {
  color: @primary-color;
}

.textarea.ant-input {
  line-height: 1.565; //1.5715;
}

.ant-select-arrow .anticon > svg {
  vertical-align: middle;
}

.swal2-popup {
  border-radius: 10px !important;
}

.swal2-title {
  font-size: 20px !important;
  color: #276BA7 !important;
  font-weight: 500 !important;
  padding: 0px;
}

.swal2-actions {
  margin: 10px auto 0px;
  button {
    width: 130px !important;
    height: 38px !important;
  }
}

.swal2-icon {
  margin: 12px auto 0px;
}


@tree-bg: transparent;